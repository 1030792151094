<template>
    <Table
        ref="tableRef"
        :isLoading="isLoading"
        :columnConfig="tableColumnConfig"
        :rows="tableShowData"
        :isPagination="true"
        :isFocusRow="true"
        :toolbox="['columnSetting', 'zooming']"
        class="table"
        :isFixedHeader="true"
        :newToolKeys="newToolKeys"
        v-model:sortDefault="sortValue"
    >
        <template v-slot:downloadPDF>
            <div class="download-pdf">
                <div class="download-pdf-btn" @click="makePdf">
                    <div class="icon download-pdf-icon" />
                    <div>下載PDF</div>
                </div>
                <div
                    ref="testPdf"
                    class="hint icon info-icon"
                    tooltip="下載檔案內容依排序顯示，不受欄位顯示與快速搜尋影響"
                    tooltip-touch
                />
            </div>
        </template>

        <template v-slot:insDetailLink="{ isHeader, row }">
            <router-link
                v-if="!isHeader"
                :to="{
                    name: 'TiaInsuranceDetail',
                    params: {
                        id: row.mainCode,
                        manCode: this.filterLog.manCode?.value,
                        source: 1
                    }
                }"
                :class="{ 'detail-link-icon': !$isMobile() }"
            >
                <Button
                    v-if="$isMobile()"
                    buttonSize="small"
                    iconClass="detail-link-icon"
                    buttonWord="明細"
                    class="mobile-button"
                />
            </router-link>
        </template>
        <template v-slot:insNo="{ isHeader, row }">
            <template v-if="!isHeader">
                <router-link
                    v-if="!isHeader"
                    :to="{
                        name: 'TiaInsuranceDetail',
                        params: {
                            id: row.mainCode,
                            manCode: this.filterLog.manCode?.value,
                            source: 1
                        }
                    }"
                    class="cell-link"
                >
                    {{ row.insNo }}
                </router-link>
            </template>
        </template>
        <template v-slot:payer="{ isHeader, row }">
            <template v-if="!isHeader">
                <a
                    class="cell-link"
                    :class="{ 'no-data': row.payer === '-' }"
                    @click.stop="openCustomer(row.payer)"
                >
                    {{ row.payer ?? '-' }}
                </a>
            </template>
        </template>
        <template v-slot:insured="{ isHeader, row }">
            <template v-if="!isHeader">
                <a
                    class="cell-link"
                    :class="{ 'no-data': row.insured === '-' }"
                    @click.stop="openCustomer(row.insured)"
                >
                    {{ row.insured ?? '-' }}
                </a>
            </template>
        </template>
    </Table>
</template>
<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

import Button from '../../components/Button.vue'
import Table from '@/components/Table.vue'

import {
    InsuranceListColumns,
    PDF_CONFIG
} from '@/assets/javascripts/property/setting'
import * as Sentry from '@sentry/vue'
import '@/assets/font/NotoSansTC.js' // 用於jsPDF載入字體

export default {
    name: 'propertyInsTable',
    components: {
        Button,
        Table
    },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    emits: [],
    methods: {
        /**
         * 組成並下載PDF
         */
        makePdf: async function () {
            try {
                this.$showLoading()
                const now = dayjs().format('YYYY-MM-DD HH:mm:ss')
                const sortedData = this.$refs.tableRef.getSortedData(
                    this.tableShowData
                )

                const doc = new jsPDF({
                    orientation: 'landscape', // 設定為橫向
                    unit: 'pt',
                    format: 'a2'
                })

                // 設置支援中文字的字體
                doc.setFont('NotoSansTC')

                // 組成table header data
                const headers = [
                    '編號',
                    '保單流水號',
                    '保單號碼',
                    '保險公司',
                    '要保人',
                    '要保人ID',
                    '被保險人',
                    '被保險人ID',
                    '生效日',
                    '營運中心',
                    '原招攬人/\n承接姓名',
                    '原招攬人/\n承接產險證號',
                    '新承接人\n親自簽名',
                    '新承接人\n產險證號',
                    '新承接人\n營業單位'
                ]

                // 組成table content data
                const body = sortedData.map((data, idx) => [
                    idx + 1,
                    data.mainCode,
                    data.insNo,
                    data.supplier,
                    data.payer,
                    data.payerId,
                    data.insured,
                    data.insuredId,
                    data.effectiveDate,
                    data.salesGroupName,
                    data.manName,
                    data.propertyRegisterNumber,
                    '',
                    '',
                    ''
                ])

                // 組成table
                autoTable(doc, {
                    margin: { top: 100 }, // 避免與上方資訊重疊
                    head: [headers],
                    body: body,
                    theme: 'grid',
                    styles: {
                        font: 'NotoSansTC',
                        fontStyle: 'normal',
                        fontSize: PDF_CONFIG.FONT_SIZE,
                        textColor: PDF_CONFIG.COLOR.BLACK,
                        halign: 'center'
                    },
                    headStyles: {
                        font: 'NotoSansTC',
                        fontStyle: 'normal', // 避免head預設使用bold
                        halign: 'center',
                        valign: 'middle'
                    },
                    columnStyles: {
                        12: { cellWidth: PDF_CONFIG.LAYOUT.EMPTY_COLUMN_WIDTH }, // 新承接人親自簽名
                        13: { cellWidth: PDF_CONFIG.LAYOUT.EMPTY_COLUMN_WIDTH }, // 新承接人產險證號
                        14: { cellWidth: PDF_CONFIG.LAYOUT.EMPTY_COLUMN_WIDTH } // 新承接人營業單位
                    },
                    didParseCell: (data) => {
                        // 調整留空column head顏色
                        if (data.section === 'head') {
                            if ([12, 13, 14].includes(data.column.index)) {
                                data.cell.styles.fillColor =
                                    PDF_CONFIG.COLOR.YELLOW
                            }
                        }
                    },
                    didDrawPage: (data) => {
                        this.setPageDescription(doc, data, now)
                    }
                })

                // 設置頁數
                const totalPages = doc.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i)
                    doc.setFontSize(PDF_CONFIG.FONT_SIZE)
                    const startX =
                        doc.internal.pageSize.getWidth() -
                        PDF_CONFIG.LAYOUT.PADDING -
                        PDF_CONFIG.LAYOUT.EMPTY_COLUMN_WIDTH * 3 // 總長 - padding - 三個欄位寬度
                    doc.text(
                        `第${i}頁/共${totalPages}頁`,
                        startX,
                        PDF_CONFIG.LAYOUT.PADDING
                    )
                }

                doc.save(
                    this.filterLog.validDate.value.replaceAll('-', '') +
                        '_有效產險清單_' +
                        this.filterLog.name
                )
            } catch (error) {
                this.$showErrorMessage(
                    '下載時出現錯誤，請重新嘗試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
                // sentry紀錄錯誤
                Sentry.withScope((scope) => {
                    scope.setTag('error-type', 'property-pdf-download-error')
                    Sentry.captureException(error)
                })
            } finally {
                this.$hideLoading()
                this.$setGaEvent(`downloadPdfToolOpen`, 'click-Button')
            }
        },
        /**
         * PDF寫入文字
         * @param {jsPDF} doc jsPDF instance
         * @param {{text: string, color: number[]}[]} texts 文字內容及顏色[R, G, B]
         * @param {number} startX X起始值
         * @param {number}  startY Y起始值
         * @description 寫入單行文字並可分別設置不同顏色
         */
        writeMutiText: function (doc, texts, startX, startY) {
            let x = startX
            texts.forEach(({ text, color }) => {
                doc.setTextColor(...color)
                doc.text(text, x, startY)
                x += doc.getTextWidth(text)
            })
            return doc
        },
        /**
         * 設置PDF table上方說明
         * @param {jsPDF} doc jsPDF instance
         * @param {HookData} data hook data
         * @param {string} now YYYY-MM-DD hh:mm:ss 下載當前時間
         */
        setPageDescription: function (doc, data, now) {
            let currentY = PDF_CONFIG.LAYOUT.PADDING

            doc.setFontSize(PDF_CONFIG.FONT_SIZE)

            const startXRight = data.table.columns
                .slice(12)
                .reduce((acc, col) => acc - col.width, data.cursor.x) // 靠右的文字對齊留空欄位

            // row 1
            doc.text(
                `＊該清單僅限於移轉磊山系統保單查詢權使用。移轉服務人員仍需請自行向保險公司辦理`,
                PDF_CONFIG.LAYOUT.PADDING,
                currentY
            )

            // row 2
            this.writeMutiText(
                doc,
                [
                    { text: '＊', color: PDF_CONFIG.COLOR.BLACK },
                    {
                        text: '新承接人需具有有效產險資格，',
                        color: PDF_CONFIG.COLOR.RED
                    },
                    {
                        text: '最多3位且限同一單位',
                        color: PDF_CONFIG.COLOR.BLACK
                    }
                ],
                PDF_CONFIG.LAYOUT.PADDING,
                (currentY += PDF_CONFIG.LAYOUT.ROW_MARGIN) // 計算並更新當前Y軸位置
            )
            doc.text(
                `總筆數: ${this.tableShowData.length}筆`,
                startXRight,
                currentY
            )

            // row 3
            this.writeMutiText(
                doc,
                [
                    { text: '＊簽署後即表示', color: PDF_CONFIG.COLOR.BLACK },
                    {
                        text: '本人及新承接人同意「',
                        color: PDF_CONFIG.COLOR.RED
                    },
                    { text: '變更未成功之保單', color: PDF_CONFIG.COLOR.BLACK },
                    { text: '」', color: PDF_CONFIG.COLOR.RED },
                    {
                        text: '，仍依磊山規定辦理。',
                        color: PDF_CONFIG.COLOR.BLACK
                    }
                ],
                PDF_CONFIG.LAYOUT.PADDING,
                (currentY += PDF_CONFIG.LAYOUT.ROW_MARGIN)
            )
            const validDate = this.filterLog.validDate.value
            doc.text(`有效區間(起): ${validDate}~`, startXRight, currentY)

            // row 4
            doc.text(
                `下載時間: ${now}`,
                startXRight,
                (currentY += PDF_CONFIG.LAYOUT.ROW_MARGIN)
            )
        },
        /**
         * 跨頁查詢客戶
         * @param {string} customerName 客戶名稱
         */
        openCustomer: function (customerName) {
            if (customerName === '-') return
            this.$setGaEvent('goSearchCustomer', 'click-Link')
            this.$crossPageFilter('TiaCustomer', { customerName })
        }
    },
    computed: {
        tableShowData: function () {
            if (this.listData.length === 0) return []
            const dataset = _.cloneDeep(this.listData)

            // 使用預設排序時增加生效日排序
            if (this.isDefaultSort) {
                dataset.sort(
                    (a, b) =>
                        new Date(b.effectiveDate) - new Date(a.effectiveDate)
                )
            }
            const res = dataset.map((data) => ({
                ...data,
                mainCode: data.mainCode.toString()
            }))
            return res
        }
    },
    watch: {
        sortValue() {
            this.isDefaultSort = false
        },
        tableShowData: {
            handler: function () {
                this.$reloadTips()
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            // table
            newToolKeys: ['downloadPDF'],
            tableColumnConfig: InsuranceListColumns,
            sortValue: { sort: 'desc', sortBy: 'supplier' },
            isDefaultSort: true
        }
    }
}
</script>

<style lang="scss" scoped>
.download-pdf {
    &-btn {
        display: flex;
    }
    .hint {
        max-width: 14px;
        margin-left: 10px;
    }
}

.cell-link {
    display: flex;
    text-decoration: underline;
    color: $primary-blue;
    cursor: pointer;
    &.no-data {
        color: $primary-black;
        cursor: default;
        text-decoration: none;
    }
}
</style>
